<template>
  <TicketItemTemplate />
</template>

<script>
import { defineComponent } from '@vue/composition-api'

import TicketItemTemplate from '@/components/Tickets/TicketItemTemplate.vue'

export default defineComponent({
  name: 'CreateTicket',
  components: {
    TicketItemTemplate,
  },
  setup() {
    //
  },
})
</script>
